import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import Cookies from 'js-cookie';
import { ClipLoader } from 'react-spinners';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import Notification from './Notification';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { DataService } from '../../../config/dataService/dataService';
import { printUserTitle } from '../utilities';

const AuthInfo = React.memo(() => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const logOutFrontend = () => {
    try {
      Cookies.remove('logedIn');
      secureLocalStorage.removeItem('needs');
      window.location.href = '/';
    } catch (err) {
      console.log(err);
    }
  };

  const logOutBackend = async () => {
    try {
      const response = await DataService.post('/logout');
      console.log(response);
      if (response.data.success) {
        logOutFrontend();
        setIsLoggedOut(false);
      }
    } catch (err) {
      console.log(err);
      logOutFrontend();
      setIsLoggedOut(false);
    }
  };

  const SignOut = (e) => {
    setIsLoggedOut(true);
    e.preventDefault();
    logOutBackend();
  };

  // eslint-disable-next-line no-unused-vars
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        {secureLocalStorage.getItem('needs') ? (
          <figure className="user-dropdwon__info">
            <img
              src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
              alt=""
              width={50}
              height={50}
            />
            <figcaption>
              <Heading as="h5">{secureLocalStorage.getItem('needs')?.name}</Heading>
              <p>{printUserTitle(secureLocalStorage.getItem('needs')?.role)}</p>
            </figcaption>
          </figure>
        ) : null}
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/admin/pages/settings/profile">
              <UilUser /> Profile
            </Link>
          </li>
          <li>
            <Link to="#">
              <UilSetting /> Paramètres
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          {isLoggedOut ? (
            <ClipLoader color="#CCD7C2" size={20} />
          ) : (
            <>
              {' '}
              <UilSignout /> Déconnexion{' '}
            </>
          )}
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Notification />
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover
          placement="bottomRight"
          content={
            secureLocalStorage.getItem('needs') ? (
              userContent
            ) : (
              <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
                <UilSignout /> Déconnexion
              </Link>
            )
          }
          action="click"
        >
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            <span className="ninjadash-nav-actions__author--name">
              {secureLocalStorage.getItem('needs') ? secureLocalStorage.getItem('needs').name : null}
            </span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
