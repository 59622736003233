import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AddUser = lazy(() => import('../../container/users/AddControlEntity'));
const UpdateUser = lazy(() => import('../../container/users/UpdateControlEntity'));
const DataTable = lazy(() => import('../../container/users/ControlEntities'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/*" element={<DataTable />} />
      <Route path="ajout" element={<AddUser />} />
      <Route path="profile-update/:id" element={<UpdateUser />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
