/* eslint-disable react/prop-types */
import { UilHeadphones } from '@iconscout/react-unicons';
import { ImStatsDots } from 'react-icons/im';
// import { MdOutlineDataSaverOff, MdOutlineBroadcastOnHome } from 'react-icons/md';
// import { TiMessageTyping } from 'react-icons/ti';
import { GiRadioTower } from 'react-icons/gi';
import { FaRegCalendarAlt, FaBookOpen, FaUsers } from 'react-icons/fa';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';

import { TbReport } from 'react-icons/tb';
import secureLocalStorage from 'react-secure-storage';
import config from '../config/config';

function MenuItems({ toggleCollapsed }) {
  const role = secureLocalStorage.getItem('needs')?.role;
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = config;

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    ![2, 5].includes(role)
      ? getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}`}>
            {t('Statistiques généraux')}
          </NavLink>,
          'Statistiques généraux',
          !topMenu && <ImStatsDots />,
        )
      : null,

    // Auditors
    [0, 1].includes(role)
      ? getItem(t('Auditeurs'), 'Auditeurs', !topMenu && <UilHeadphones />, [
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/auditeurs`}>
              {t('Liste des auditeurs')}
            </NavLink>,
            'Liste des auditeurs',
            null,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/auditeurs/top10`}>
              {t('Top 10 des meilleurs auditeurs')}
            </NavLink>,
            'Top 10 des meilleurs auditeurs',
            null,
          ),
        ])
      : null,

    ![2, 5].includes(role)
      ? getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/impact-des-radios`}>
            {t(![0, 3].includes(role) ? 'Impact des Radios' : 'Classement mensuel des radios')}
          </NavLink>,
          ![0, 3].includes(role) ? 'Impact des Radios' : 'Classement mensuel des radios',
          !topMenu && <GiRadioTower />,
        )
      : null,

    role === 1
      ? getItem(t('Partenaires'), 'Partenaires', !topMenu && <FaUsers />, [
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/partenaires`}>
              {t('Liste des partenaires')}
            </NavLink>,
            'Liste des partenaires',
            null,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/partenaires/ajout`}>
              {t('Ajouter un partenaire')}
            </NavLink>,
            'Ajouter un partenaire',
            null,
          ),
        ])
      : null,

    role === 3
      ? getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/contrats`}>
            {t('Gestions des contrats')}
          </NavLink>,
          'Gestions des contrats',
          !topMenu && <FaBookOpen />,
        )
      : null,

    role === 0
      ? getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/gestion-contrats`}>
            {t('Gestions des contrats')}
          </NavLink>,
          'Gestions des contrats',
          !topMenu && <FaBookOpen />,
        )
      : null,

    [2, 5].includes(role)
      ? getItem(t('Contrôle de flux'), 'Contrôle de flux', !topMenu && <TbReport />, [
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/gestion-de-scan`}>
              {t('Gestion de scan')}
            </NavLink>,
            'Gestion de scan',
            null,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/liste-des-emissions`}>
              {t('Liste des Émissions')}
            </NavLink>,
            'Liste des Émissions',
            null,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/controle-de-flux`}>
              {t('Émissions par radio')}
            </NavLink>,
            'Émissions par radio',
            null,
          ),
        ])
      : null,

    [2, 5].includes(role)
      ? getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/gestion-des-notifications`}>
            {t('Gestion des notifications')}
          </NavLink>,
          'Gestion des notifications',
          !topMenu && <FaRegCalendarAlt />,
        )
      : null,

    [2, 3, 5].includes(role)
      ? getItem(
          <NavLink onClick={toggleCollapsed} to={`${path}/grille-programmes`}>
            {t('Grille des programmes')}
          </NavLink>,
          'Grille des programmes',
          !topMenu && <FaRegCalendarAlt />,
        )
      : null,

    [1, 5].includes(role)
      ? getItem(t('Gestion des Administrateurs'), 'Gestion des Administrateurs', !topMenu && <FaUsers />, [
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/organismes-de-controle`}>
              {t('Liste des Administrateurs')}
            </NavLink>,
            'Liste des Administrateurs',
            null,
          ),
          getItem(
            <NavLink onClick={toggleCollapsed} to={`${path}/organismes-de-controle/ajout`}>
              {t('Ajouter un Administrateur')}
            </NavLink>,
            'Ajouter un Administrateur',
            null,
          ),
        ])
      : null,

    // getItem(t('Préoccupations Techniques'), 'Préoccupations Techniques', !topMenu && <TiMessageTyping />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/chat/discussions`}>
    //       {t('Chat')}
    //     </NavLink>,
    //     'Chat',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/tickets`}>
    //       {t('Mes tickets')}
    //     </NavLink>,
    //     'Mes tickets',
    //     null,
    //   ),
    //   role === 1
    //     ? getItem(
    //         <NavLink onClick={toggleCollapsed} to={`${path}/preoccupations-techniques`}>
    //           {t('Liste des préoccupations')}
    //         </NavLink>,
    //         'Liste des préoccupations',
    //         null,
    //       )
    //     : null,
    // ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

export default MenuItems;
