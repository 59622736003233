import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
import Pages from './pages';
import ControlEntities from './control-entities';
import Partners from './partners';
import withAdminLayout from '../../layout/withAdminLayout';

// Customized component for AWR Monitoring pages
const Reserved = lazy(() => import('../reserved'));
const Listeners = lazy(() => import('../../container/Listeners/Index'));
const Contrat = lazy(() => import('../../container/Contrat/Index'));
const AgreementDocument = lazy(() => import('../../container/AdminContracts/AgreementDocument'));
const AddTicket = lazy(() => import('../../container/Tickets/AddTicket'));
const TicketDetails = lazy(() => import('../../container/Tickets/TicketDetails'));
/* const ListeningTime = lazy(() => import('../../container/ListeningTime/Index'));
const BroadcastFrequency = lazy(() => import('../../container/BroadcastFrequency/Index')); */
const TopTenListeners = lazy(() => import('../../container/TopTenListeners/Index'));
const RadiosImpact = lazy(() => import('../../container/RadiosImpact/Index'));
const StreamAnalysisManager = lazy(() => import('../../container/StreamAnalysisManager/Index'));
const Chat = lazy(() => import('../../container/chat/ChatApp'));

// Template components
const Components = lazy(() => import('./components'));
const Calendars = lazy(() => import('../../container/calendar/Calendar'));
const Preoccupations = lazy(() => import('./preoccupations'));
const AdminContracts = lazy(() => import('./adminContracts'));
const SummaryReport = lazy(() => import('./summaryReport'));
const StreamAnalysis = lazy(() => import('./streamAnalysis'));
const EventsManagement = lazy(() => import('./eventsManagement'));
const NotificationsManagement = lazy(() => import('./notificationsManagement'));
const Profile = lazy(() => import('../../container/profile/settings/Settings'));
const Maps = lazy(() => import('./maps'));
const Icons = lazy(() => import('./icons'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        {/* Used Routes for AWR Monitoring */}
        <Route index path="/*" element={<Dashboard />} />
        <Route path="auditeurs/*" element={<Listeners />} />
        <Route path="auditeurs/top10" element={<TopTenListeners />} />
        <Route path="tickets" element={<AddTicket />} />
        <Route path="tickets/:id" element={<TicketDetails />} />
        {/* <Route path="temps-moyen-ecoute" element={<ListeningTime />} />
        <Route path="frequence-de-diffusion" element={<BroadcastFrequency />} /> */}
        <Route path="preoccupations-techniques/*" element={<Preoccupations />} />
        <Route path="summary-report/*" element={<SummaryReport />} />
        <Route path="controle-de-flux/*" element={<StreamAnalysis />} />
        <Route path="liste-des-emissions" element={<EventsManagement />} />
        <Route path="gestion-des-notifications" element={<NotificationsManagement />} />
        <Route path="gestion-de-scan" element={<StreamAnalysisManager />} />
        <Route path="chat/*" element={<Chat />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="contrats/">
          <Route path="" element={<Contrat />} />
          <Route path=":slug/document" element={<AgreementDocument />} />
        </Route>
        <Route path="gestion-contrats/*" element={<AdminContracts />} />
        <Route path="impact-des-radios" element={<RadiosImpact />} />
        <Route path="grille-programmes/*" element={<Calendars />} />
        <Route path="organismes-de-controle/*" element={<ControlEntities />} />
        <Route element={<Reserved />}>
          <Route path="partenaires/*" element={<Partners />} />
        </Route>
        <Route path="404" element={<NotFound />} />

        {/* Initial routes */}
        <Route path="pages/*" element={<Pages />} />
        <Route path="components/*" element={<Components />} />
        {/* <Route path="app/kanban/*" element={<Kanban />} /> */}
        <Route path="maps/*" element={<Maps />} />
        <Route path="icons/*" element={<Icons />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
